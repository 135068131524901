/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge
} from "shards-react";

import PageTitle from "../components/common/PageTitle";


class Marketplace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      PostsListOne: [
        {
          backgroundImage: require("../images/companyLogos/airwallex.png"),
          category: "Forex",
          categoryTheme: "info",
          title:
            "Airwallex",
          body:
            "Save money on your Forex when paying Facebook Ads or International Suppliers. You can even create digital expense cards for your employees and team members.",
          offer: "1% Saasback on transactions",
          offerLink: "https://www.airwallex.com/"
        },
        {
          backgroundImage: require("../images/companyLogos/gorgias.png"),
          category: "Customer Service",
          categoryTheme: "dark",
          title:
            "Gorgias",
          body:
            "The top Customer Service platform for E-commerce. Direct integrations with Shopify mean that you're customer service team can perform actions directly.",
          offer: "5% Saasback on your Gorgias Spend",
          offerLink: "https://www.gorgias.com/"
        },
        {
            backgroundImage: require("../images/companyLogos/airwallex.png"),
            category: "Forex",
            categoryTheme: "info",
            title:
              "Airwallex",
            body:
              "Save money on your Forex when paying Facebook Ads or International Suppliers. You can even create digital expense cards for your employees and team members.",
            offer: "1% Saasback on transactions",
            offerLink: "https://www.airwallex.com/"
          },
          {
            backgroundImage: require("../images/companyLogos/gorgias.png"),
            category: "Customer Service",
            categoryTheme: "dark",
            title:
              "Gorgias",
            body:
              "The top Customer Service platform for E-commerce. Direct integrations with Shopify mean that you're customer service team can perform actions directly.",
            offer: "5% Saasback on your Gorgias Spend",
            offerLink: "https://www.gorgias.com/"
          },
          {
            backgroundImage: require("../images/companyLogos/airwallex.png"),
            category: "Forex",
            categoryTheme: "info",
            title:
              "Airwallex",
            body:
              "Save money on your Forex when paying Facebook Ads or International Suppliers. You can even create digital expense cards for your employees and team members.",
            offer: "1% Saasback on transactions",
            offerLink: "https://www.airwallex.com/"
          },
          {
            backgroundImage: require("../images/companyLogos/gorgias.png"),
            category: "Customer Service",
            categoryTheme: "dark",
            title:
              "Gorgias",
            body:
              "The top Customer Service platform for E-commerce. Direct integrations with Shopify mean that you're customer service team can perform actions directly.",
            offer: "5% Saasback on your Gorgias Spend",
            offerLink: "https://www.gorgias.com/"
          }
      ]
    };
  }

  render() {
    const {
      PostsListOne
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Marketplace" subtitle="Choose your Tech Stack" className="text-sm-left" />
        </Row>
        {/* First Row of Posts */}
        <Row>
          {PostsListOne.map((post, idx) => (
            <Col lg="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--aside card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.category}
                  </Badge>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a className="text-fiord-blue" href={post.offerLink}>
                      {post.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  <a className="offer-link" target="_blank" rel="noopener noreferrer" href={post.offerLink}><span className="text-muted">{post.offer}</span></a>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default Marketplace;
